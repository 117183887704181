/** @jsx jsx */
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { jsx, Text, Styled } from "theme-ui"

import { Container } from "components/layout"
import Bars from "components/svgs/bars"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "hero/joyce-centre.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 875) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div>
      <div
        sx={{
          display: ["block", "flex"],
          alignItems: "center",
          bg: "dark",
        }}
      >
        <div
          sx={{
            height: "100%",
            flex: "2",
            p: 3,
          }}
        >
          <Text sx={{ color: "background", fontSize: [5, 6] }}>
            2018 - 2019
          </Text>
          <Bars />
          <Styled.h1 sx={{ color: "background" }} variant="large">
            Sustainability Report
          </Styled.h1>
        </div>
        <div
          sx={{
            flex: "3",
          }}
        >
          <Img fluid={data.heroImage.childImageSharp.fluid} />
        </div>
      </div>
      <div sx={{ bg: "primary" }}>
        <Container>
          <Text
            sx={{ fontWeight: "bold", fontSize: [3, 4], color: "background" }}
          >
            Learning. Living. Leading.<sup>&trade;</sup> Sustainability at
            Mohawk
          </Text>
        </Container>
      </div>
    </div>
  )
}

export default Hero
