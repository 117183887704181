import React from "react"

const Computer = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="73" height="61" viewBox="0 0 73 61" fill="none">
    <path
      fill="currentColor"
      d="M37.592 51.834a.523.523 0 01-.024-.53.514.514 0 01.453-.27 3.042 3.042 0 000-6.083H7.604a1.52 1.52 0 01-1.52-1.521V8.45a1.52 1.52 0 011.52-1.52h50.188a1.521 1.521 0 011.52 1.52v1.522a3.042 3.042 0 006.084 0V6.93A6.083 6.083 0 0059.312.847H6.084A6.083 6.083 0 000 6.93v38.02a6.083 6.083 0 006.083 6.084h19.312a.76.76 0 01.633 1.18l-3.72 5.579a1.521 1.521 0 001.265 2.366h16.73a1.52 1.52 0 001.264-2.366l-3.975-5.959z"
    ></path>
    <path
      fill="currentColor"
      d="M69.958 17.576H48.667a3.041 3.041 0 00-3.042 3.041v36.5a3.042 3.042 0 003.042 3.042h21.291A3.041 3.041 0 0073 57.118v-36.5a3.041 3.041 0 00-3.042-3.042zm-3.041 9.882h-6.084a2.282 2.282 0 010-4.562h6.084a2.282 2.282 0 010 4.562zm.76 23.573a2.282 2.282 0 01-2.281 2.281H53.229a2.281 2.281 0 110-4.562h12.167a2.28 2.28 0 012.281 2.284v-.003z"
    ></path>
  </svg>
)

export default Computer
