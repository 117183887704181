import React from "react"

const Co2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="71" height="54" viewBox="0 0 71 54" fill="none">
    <path
      fill="currentColor"
      d="M35.52 53.99c-5.705 0-11.41-.003-17.116 0-9.328.002-17.034-6.88-18.25-16.3-1.079-8.36 3.619-16.407 11.396-19.485.515-.204.747-.486.928-1.015C15.764 7.563 22.335 1.713 32.26.26c11.903-1.743 22.77 5.497 26.336 17.123.088.285.38.595.654.713 6.594 2.839 10.53 7.818 11.572 15.029 1.401 9.705-5.671 19.271-15.231 20.58-2.208.302-4.466.256-6.702.273-4.457.04-8.912.012-13.369.012zm10.078-19.896c0-5.51-2.431-8.505-6.866-9.069-3.99-.506-7.4 1.56-8.708 5.301-.543 1.556-.673 3.17-.492 4.812.337 3.06 1.805 5.373 4.624 6.518 2.9 1.18 5.793.992 8.354-1.018 2.284-1.795 3.11-4.3 3.088-6.544zm-18.11 3.866c-2.23.917-2.968 1.067-4.559.946-1.955-.146-3.568-1.4-4.075-3.233-.39-1.412-.388-2.85.096-4.242.544-1.57 1.61-2.626 3.224-3.035 1.568-.397 3.085-.158 4.55.48l.77.337c0-1.087-.025-2.042.012-2.997.02-.495-.13-.791-.62-.84-1.378-.138-2.762-.429-4.132-.36-3.53.176-6.122 1.873-7.463 5.287-.634 1.614-.716 3.317-.56 5.022.206 2.224 1.07 4.142 2.92 5.425 2.986 2.068 6.204 1.904 9.467.797.155-.052.348-.288.354-.443.03-1.021.017-2.045.017-3.144zm24.415 3.733c-.02-.054-.036-.109-.056-.164.829-.736 1.726-1.409 2.465-2.229.719-.796 1.452-1.668 1.87-2.646.855-1.999-.113-3.95-2.108-4.553-1.687-.51-3.26-.127-4.746.736-.105.06-.224.207-.226.317-.023.652-.012 1.308-.012 1.794.965-.316 1.868-.776 2.81-.88 1.483-.164 2.307 1.067 1.676 2.45-.328.717-.875 1.358-1.42 1.94-.915.966-1.849 1.927-2.885 2.752-.996.794-.798 1.746-.724 2.744h7.567v-2.26h-4.21z"
    ></path>
    <path
      fill="currentColor"
      d="M41.645 33.682c0 .995-.246 1.927-.515 2.784-.47 1.499-1.542 2.39-3.085 2.577-1.647.199-3.076-.276-3.908-1.83-1.28-2.384-1.288-4.808.09-7.161.88-1.505 2.273-1.982 3.937-1.804 1.542.164 2.392 1.2 2.875 2.526.343.943.55 1.913.606 2.908z"
    ></path>
  </svg>
)

export default Co2
