import React from "react"

const Apple = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="61" height="69" viewBox="0 0 61 69" fill="none">
    <path
      fill="currentColor"
      d="M47.492 17.406c-4.804-.843-13.26 1.935-17.199 4.152-3.938-2.217-12.395-4.996-17.2-4.152C2.106 19.333-1.434 34.183.503 45.113c1.939 10.931 8.29 23.888 21.19 23.888 1.632 0 3.262-.622 4.658-1.394a8.174 8.174 0 017.886 0c1.395.772 3.026 1.394 4.656 1.394 12.9 0 19.252-12.957 21.188-23.888 1.938-10.93-1.6-25.78-12.589-27.707zm-7.512-5.597C44.113 7.69 43.188.089 43.188.089s-7.629-.924-11.762 3.196c-4.134 4.12-3.207 11.72-3.207 11.72s7.627.924 11.761-3.196z"
    ></path>
  </svg>
)

export default Apple
