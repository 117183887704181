import React from "react"

const Star = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="75" height="71" viewBox="0 0 75 71" fill="none">
    <path
      fill="currentColor"
      d="M33.564 2.435l-8.956 18.102L4.57 23.449c-3.594.52-5.034 4.935-2.428 7.465l14.497 14.082-3.429 19.892c-.617 3.596 3.182 6.29 6.364 4.608L37.5 60.103l17.926 9.393c3.182 1.668 6.98-1.012 6.364-4.608l-3.43-19.892 14.498-14.082c2.606-2.53 1.166-6.946-2.428-7.465l-20.038-2.912-8.956-18.102c-1.604-3.226-6.254-3.267-7.872 0z"
    ></path>
  </svg>
)

export default Star
