import React from "react"

const Building = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="69"
    height="57"
    fill="none"
    viewBox="0 0 69 57"
  >
    <path
      fill="currentColor"
      d="M69 2.156A2.157 2.157 0 0066.844 0H2.156a2.156 2.156 0 000 4.313h2.156A1.438 1.438 0 015.75 5.75v48.875a1.438 1.438 0 001.438 1.438h18.687a1.438 1.438 0 001.438-1.438v-7.188a7.188 7.188 0 0114.375 0v7.188a1.438 1.438 0 001.437 1.438h18.688a1.438 1.438 0 001.437-1.438V5.75a1.438 1.438 0 011.438-1.438h2.156A2.156 2.156 0 0069 2.156zM26.594 34.5h-11.5a2.156 2.156 0 110-4.313h11.5a2.156 2.156 0 010 4.313zm0-11.5h-11.5a2.156 2.156 0 110-4.313h11.5a2.156 2.156 0 010 4.313zm0-11.5h-11.5a2.156 2.156 0 110-4.313h11.5a2.156 2.156 0 010 4.313zm27.312 23h-11.5a2.156 2.156 0 010-4.313h11.5a2.156 2.156 0 010 4.313zm0-11.5h-11.5a2.156 2.156 0 010-4.313h11.5a2.156 2.156 0 010 4.313zm2.157-13.656a2.156 2.156 0 01-2.157 2.156h-11.5a2.156 2.156 0 010-4.313h11.5a2.157 2.157 0 012.157 2.157z"
    ></path>
  </svg>
)

export default Building
