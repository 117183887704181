import React from "react"

const Light = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="64" viewBox="0 0 45 64" fill="none">
    <path
      fill="currentColor"
      d="M32.667 53.5V57c0 1.306-.853 2.417-2.042 2.829V61c0 1.657-1.371 3-3.063 3H17.355c-1.691 0-3.062-1.343-3.062-3v-1.171c-1.19-.412-2.042-1.523-2.042-2.829v-3.5c0-.828.686-1.5 1.531-1.5h17.354c.846 0 1.532.672 1.532 1.5zM14.406 48c-1.265 0-2.403-.76-2.856-1.916C6.597 33.443 0 34.716 0 22 0 9.85 10.056 0 22.458 0c12.403 0 22.459 9.85 22.459 22 0 12.716-6.597 11.443-11.55 24.084C32.913 47.24 31.774 48 30.51 48H14.406zM12.25 22c0-5.514 4.58-10 10.208-10 1.128 0 2.042-.896 2.042-2s-.914-2-2.042-2c-7.88 0-14.291 6.28-14.291 14 0 1.105.914 2 2.041 2 1.128 0 2.042-.895 2.042-2z"
    ></path>
  </svg>
)

export default Light
