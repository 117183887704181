/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import { Container } from "components/layout"
import { TileLink } from "components/tiles"

const Topics = () => {
  const data = useStaticQuery(graphql`
    query {
      numbersImage: file(relativePath: { eq: "thumb/numbers.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      climateImage: file(relativePath: { eq: "thumb/climate.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      buildingsImage: file(relativePath: { eq: "thumb/buildings.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      transportationImage: file(
        relativePath: { eq: "thumb/transportation.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wasteImage: file(relativePath: { eq: "thumb/waste.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      participationImage: file(
        relativePath: { eq: "thumb/participation.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foodImage: file(relativePath: { eq: "thumb/food.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      academicsImage: file(relativePath: { eq: "thumb/academics.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container>
      <Styled.h1>Main Topics</Styled.h1>
      <div
        sx={{
          display: ["block", "grid"],
          my: 4,
          gridGap: 3,
          gridTemplateColumns: ["auto", "1fr 1fr 1fr"],
        }}
      >
        <div sx={{ gridColumn: "1" }}>
          <TileLink
            to="/numbers"
            image={data.numbersImage.childImageSharp.fluid}
          >
            By the Numbers
          </TileLink>
        </div>
        <div sx={{ gridColumn: "span 2" }}>
          <TileLink to="/climate" image={data.climateImage.childImageSharp.fluid}>
            Climate &amp; Energy
          </TileLink>
        </div>
        <div sx={{ gridRow: "span 2" }}>
          <TileLink
            to="/buildings"
            image={data.buildingsImage.childImageSharp.fluid}
          >
            Buildings &amp; Grounds
          </TileLink>
        </div>
        <div sx={{ gridColumn: "2" }}>
          <TileLink
            to="/transportation"
            image={data.transportationImage.childImageSharp.fluid}
          >
            Transportation
          </TileLink>
        </div>
        <div sx={{ gridColumn: "3" }}>
          <TileLink to="/waste" image={data.wasteImage.childImageSharp.fluid}>
            Waste
          </TileLink>
        </div>
        <div sx={{ gridColumn: "1" }}>
          <TileLink
            to="/participation"
            image={data.participationImage.childImageSharp.fluid}
          >
            Participation
          </TileLink>
        </div>
        <div sx={{ gridColumn: "2 / span 2", gridRow: "3 / span 2" }}>
          <TileLink to="/food" image={data.foodImage.childImageSharp.fluid}>
            Food Systems
          </TileLink>
        </div>
        <div sx={{ gridColumn: "span 3" }}>
          <TileLink
            to="/academics"
            image={data.academicsImage.childImageSharp.fluid}
          >
            Academics &amp; Applied Research
          </TileLink>
        </div>
      </div>
    </Container>
  )
}

export default Topics
