import React from "react"

const Bin = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="51" height="65" viewBox="0 0 51 65" fill="none">
    <path
      fill="currentColor"
      d="M41.351.918H4.135A4.135 4.135 0 000 5.053v5.514a1.378 1.378 0 001.378 1.378h48.244A1.378 1.378 0 0051 10.567 9.649 9.649 0 0041.351.918zm4.136 15.162H4.135a1.378 1.378 0 00-1.378 1.379v31.559a.678.678 0 001.05.576 10.33 10.33 0 0114.801 13.663.695.695 0 00.576 1.066H32.56a1.378 1.378 0 001.323-1l12.921-45.487a1.379 1.379 0 00-1.317-1.756z"
    ></path>
    <path
      fill="currentColor"
      d="M9.649 64.323a6.203 6.203 0 100-12.405 6.203 6.203 0 000 12.405z"
    ></path>
  </svg>
)

export default Bin
