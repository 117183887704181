/** @jsx jsx */
import { Fragment } from "react"
import { jsx, Styled } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Container } from "components/layout"
import PartialCollpase from "components/partial-collapse"

const President = () => {
  const data = useStaticQuery(graphql`
    query {
      presidentImage: file(relativePath: { eq: "president.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Fragment>
      <div
        sx={{
          bg: "primary",
        }}
      >
        <Container>
          <div
            sx={{
              alignItems: "flex-start",
              display: "flex",
              position: "relative",
              width: "100%",
            }}
          >
            <div sx={{ width: ["100%", "60%"] }}>
              <Styled.h1
                sx={{
                  color: "background",
                  fontSize: [4, 5, 7],
                }}
              >
                Letter from the President
              </Styled.h1>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div
          sx={{
            alignItems: "flex-start",
            display: "flex",
            flexWrap: "wrap",
            position: "relative",
            width: "100%",
          }}
        >
          <div sx={{ order: 2, width: ["100%", "40%"] }}>
            <Img
              sx={{
                mt: [0, -6],
              }}
              fluid={data.presidentImage.childImageSharp.fluid}
            />
          </div>
          <div sx={{ width: ["100%", "60%"], pl: 3, pr: [3, 5] }}>
            <PartialCollpase>
              <Styled.p>
                Mohawk College committed to being a leader in environmental
                sustainability when we adopted our first comprehensive
                Environmental Management Plan (EMP) in 2011.
              </Styled.p>
              <Styled.p>
                The plan was the first of its kind for an Ontario college. It
                set bold targets for energy and waste reduction, and included a
                call for a 20% reduction in 2007 baseline carbon emissions by
                2020. We exceeded this target in 2014, six years ahead of
                schedule. A second plan, EMP 2.0, followed in 2015 and committed
                the college to a 30% reduction in 2007 baseline emissions by
                2020.
              </Styled.p>
              <Styled.p>
                We've come a long way since then. Today, we have reduced our
                direct carbon emissions by 63%. At the same time, we have taken
                aggressive action to reduce waste and introduce policies and
                initiatives that encourage sustainable practices among students,
                employees and the community. We also built Canada's largest and
                the region's first zero carbon institutional building, The Joyce
                Centre for Partnership &amp; Innovation.
              </Styled.p>
              <Styled.p>
                These successes have established Mohawk as a climate change
                leader and innovator within the community and the education
                sector, and have helped to develop strong connections with
                government and industry that are leading to new and exciting
                opportunities for our students and community.
              </Styled.p>
              <Styled.p>
                The following report highlights the work that has been done to
                improve the sustainability of our campuses and the success of
                EMP 2.0, with a special focus on 2018 and 2019. It was during
                these years that Mohawk celebrated a number of key milestones,
                including becoming the first college in Ontario to achieve a
                Gold rating through the Sustainability Tracking, Assessment &
                Rating System (STARS<sup>&reg;</sup>) of the Association for the
                Advancement of Sustainability in Higher Education (AASHE).
              </Styled.p>
              <Styled.p>
                One of the achievements that contributed to the STARS
                <sup>&reg;</sup> Gold rating was the opening of The Joyce Centre
                for Partnership & Innovation. At 96,700 square feet, The Joyce
                Centre is an example of what can be done to fight climate
                change. It is also a unique living lab for our students, faculty
                and staff. In its first year of operation, the building exceeded
                its design goals, generating 115% of the energy it needed to
                operate through renewable sources, and quickly becoming a case
                study in zero carbon building operations.
              </Styled.p>
              <Styled.p>
                The report also provides a snapshot of sustainability
                initiatives and progress in campus operations, student
                engagement, and curriculum and research. While we've made great
                progress, we know that much work remains to be done, and new
                challenges present themselves each day. That's why we're
                launching EMP 3.0 in 2020. Building on our successes, EMP 3.0
                will help us continue the transition to a low-carbon future for
                our students and for the region.
              </Styled.p>
            </PartialCollpase>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default President
