/** @jsx jsx */
import { jsx, Styled, Text, Box } from "theme-ui"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "./timeline.css"

import { Container } from "components/layout"

import timelineData from "data/timeline"

const Timeline = () => {
  const settings = {
    dots: false,
  }

  return (
    <Container
      sx={{
        my: 4,
      }}
    >
      <Styled.h1>Our Timeline</Styled.h1>
      <Box variant="timeline.wrapper">
        <Slider {...settings}>
          {timelineData.map(item => (
            <div key={item.year}>
              <div
                sx={{
                  alignItems: "flex-start",
                  display: "flex",
                  pr: 5,
                }}
              >
                <Text as="p" variant="timeline.year">
                  {item.year}
                </Text>
                <div>
                  {item.paragraphs.map((paragraph, i) => (
                    <Text as="p" key={`key-${i}`} sx={{ fontSize: [3, 4] }}>
                      {paragraph}
                    </Text>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </Box>
    </Container>
  )
}

export default Timeline
