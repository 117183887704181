/** @jsx jsx */
import { Fragment } from "react"
import { jsx, Box } from "theme-ui"

import { Container } from "components/layout"
import Title from "components/title"
import Infographic from "components/infographic"

import Apple from "components/svgs/apple"
import Bike from "components/svgs/bike"
import Bin from "components/svgs/bin"
import Building from "components/svgs/building"
import Co2 from "components/svgs/co2"
import Computer from "components/svgs/computer"
import Light from "components/svgs/light"
import Star from "components/svgs/star"

const Numbers = () => (
  <Fragment>
    <Title color="background" bg="darkPrimary" as="h1">
      By the Numbers
    </Title>
    <Container>
      <div
        sx={{
          display: ["block", "block", "flex"],
          py: 4,
          justifyContent: "space-between",
        }}
      >
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Infographic value="2,000+" icon={<Apple />}>
            2,000+ annual participants in local food programs
          </Infographic>
          <Infographic
            value={
              <Fragment>
                167,705 ft<sup>2</sup>
              </Fragment>
            }
            icon={<Building />}
          >
            167,705 ft<sup>2</sup> of LEED<sup>&reg;</sup> and Zero Carbon
            certified green buildings
          </Infographic>
          <Infographic value="63%" icon={<Bin />}>
            63% diversion of waste from landfill
          </Infographic>
          <Infographic value="18" icon={<Light />}>
            18 campus sustainability initiatives implemented from student ideas
          </Infographic>
        </div>
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Infographic value="100%" icon={<Computer />}>
            100% of academic departments offer sustainability courses
          </Infographic>
          <Infographic value="65%" icon={<Bike />}>
            65% of trips by sustainable modes of transportation
          </Infographic>
          <Box
            sx={{
              bg: "darkPrimary",
              color: "background",
              p: 3,
            }}
          >
            <Infographic value="63%" icon={<Co2 />} color="background">
              63% reduction in absolute GHG emissions (Scopes 1 &amp; 2) since
              2007
            </Infographic>
            <Infographic
              value={
                <Fragment>
                  1<sup>st</sup>
                </Fragment>
              }
              icon={<Star />}
              color="background"
            >
              1<sup>st</sup> college in Ontario to receive AASHE STARS
              <sup>&reg;</sup> Gold rating
            </Infographic>
          </Box>
        </div>
      </div>
    </Container>
  </Fragment>
)

export default Numbers
