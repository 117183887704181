import React from "react"

export default [
  {
    year: 2008,
    paragraphs: [
      <>
        Mohawk establishes the Sustainability Office and the Go Green Committee
        (GGC).
      </>,
    ],
  },
  {
    year: 2010,
    paragraphs: [
      <>The Sustainability Steering Committee (SSC) is formed.</>,
      <>Baseline GHG inventory commissioned for the 2007 calendar year.</>,
    ],
  },
  {
    year: 2011,
    paragraphs: [
      <>Mohawk publishes its first Environmental Management Plan, 2011-2014.</>,
    ],
  },
  {
    year: 2012,
    paragraphs: [
      <>
        Mohawk achieves 24% reduction in CO<sub>2</sub>e emissions (Scopes 1, 2, and 3)
        against the 2007 baseline.
      </>,
    ],
  },
  {
    year: 2014,
    paragraphs: [
      <>New, five-year Environmental Management Plan (EMP 2.0) is published.</>,
    ],
  },
  {
    year: 2016,
    paragraphs: [
      <>
        Completion of first AASHE STARS<sup>&reg;</sup> rating, achieving
        Silver.
      </>,
    ],
  },
  {
    year: 2017,
    paragraphs: [
      <>
        Centre for Climate Change Management at Mohawk (CCCM) launches as a
        regional hub for collaboration on climate action.
      </>,
    ],
  },
  {
    year: 2018,
    paragraphs: [
      <>
        The Joyce Centre opens as a national pilot project demonstrating the
        CaGBC's new Zero Carbon Building Standard.
      </>,
    ],
  },
  {
    year: 2019,
    paragraphs: [
      <>
        Mohawk achieves Gold rating in AASHE STARS<sup>&reg;</sup>.
      </>,
      <>IDEAWORKS Research Chair in Sustainability is established.</>,
    ],
  },
]
