import React from "react"

const Bars = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="196" height="12" viewBox="0 0 196 12" fill="none">
    <path fill="#B2BB1E" d="M153.379.988h41.688V12h-41.688z"></path>
    <path fill="#760A30" d="M0 .988h40.901V12H0z"></path>
    <path fill="#AF1232" d="M51.127.988h40.901V12H51.127z"></path>
    <path fill="#F17C14" d="M101.466.988h41.688V12h-41.688z"></path>
  </svg>
)

export default Bars
